body {
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

.clear-search-button {
    position: absolute;
    right: 6%;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #666;
}

.site-logo {
    height: auto;
    width: 120px;
    position: absolute;
    top: 10px;
    left: 10px;
}


.ListView {
    background-color: #f0f0f0;
    text-align: center;
    margin: 0 auto;
}

.ListView input[type="text"] {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
}

.ListView input[type="number"] {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
}

.ListViewContainer{
    padding: 5%;
}

.button {
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.header-buttons {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
}

.ListView ul {
    list-style-type: none;
    padding: 0;
}

.ListView li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.ListViewContainer {
    position: relative;
}

.LogoutButton {
    background: #4f4f4f;
}

.entry-item {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.entry-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.entry-field {
    flex: 1;
    margin: 10px;
    text-align: left;
    min-width: 200px;
}

.entry-text-info {
    display: block;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 5px;
}

.entry-text {
    display: block;
    font-weight: bold;
    font-size: 16px;
    word-wrap: break-word;
}

.error-message {
    color: red;
    font-size: 16px;
    width: 100%;
}

.entry-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
}

.amount-input {
    width: 60px;
    margin-right: 3px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.search-section input[type="text"]{
    width: 100%;
    background: #008fff0f;
}

.category-buttons{
    margin-bottom: 10px;
}

.CategorySections{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.CategorySections input[type="text"]{
    width: 471px;
}

.AddEntrySection {
    margin-bottom: 10px;
}

.AddEntrySection h3 {
    text-align: center;
}

.EntryRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input-group {

}

.EntryRow input[type="text"], .EntryRow input[type="number"] {
    flex: 1;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-width: 150px;
}

.button {
    margin: 3px;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button-normal {
    background: #0056b3;
}

.button-normal:hover {
    background: #007bff;
}

.subtract-button {
    background-color: #ff4d4d;
    color: white;
}

.add-button {
    background-color: #4caf50;
    color: white;
}

.remove-button {
    background-color: #850800;
    color: white;
}

.button-inactive {
    background: #4f4f4f;
    opacity: 0.7;
}

.button-active {
    background: #4f4f4f;
    opacity: 1;
}

.creator{
    display: block;
    font-weight: normal;
    font-size: 12px;
}

/* Responsive Styles for Tablets and Smaller Screens */
@media (max-width: 1024px) {
    .entry-actions {
        flex-direction: row;
    }

    .entry-field {
        margin: 5px 0;
    }

    .amount-input {
        width: 60px;
    }
}

@media (max-width: 768px) {
    .CategorySections{
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .CategorySections input[type="text"]{
        width: 100%;
        margin-bottom: 10px;
    }

    .ListView input[type="text"]  .ListView input[type="number"]{
        width: 100%;
    }

    .EntryRow {
        display: flex;
        flex-direction: column;
    }

    .input-group {
        width: 100%;
    }

    .action-buttons{
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .entry-info {
        flex-direction: column;
    }
}