.ButtonPanel{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Panel{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.Panel_content{
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #ff00000f;
    max-height: 500px;
    overflow-y: scroll;
}

.Panel_content_logs{
    background-color: rgba(0, 34, 255, 0.06) !important;
}

.Panel_content_li {
    border-bottom: 1px solid #fff !important;
}

@media (max-width: 1024px) {

}

@media (max-width: 768px) {

}