body {
    background-color: #f0f0f0;
}

.FormContainer {
    width: 300px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.FormContainer h2 {
    color: #333;
}

.FormContainer label {
    display: block;
    text-align: left;
    margin: 10px 0 5px;
}

.FormContainer input[type="text"],
.FormContainer input[type="password"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.FormContainer button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 15px;
}

.FormContainer button:hover {
    background-color: #0056b3;
}
