.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ababab;
    z-index: 1000;
    border-radius: 8px;
}

.popup-content {
    text-align: center;
}

.popup-buttons {
    margin-top: 20px;
}

.button.confirm {
    background-color: #4CAF50; /* Grüner Button */
}

.button.cancel {
    background-color: #f44336; /* Roter Button */
}
